import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import CreateIcon from "../../../assets/create-icon";
import defaultIcon from "../../../assets/default-node-icon.png";
import DeleteIcon from "../../../assets/delete-icon";
import EditIcon from "../../../assets/edit-icon";
import MenuIcon from "../../../assets/menu-icon";

import { deleteNode, getNodes } from "../../../api/nodes";
import SearchSortFilter from "../../SearchFilterSort";

import { IMAGE_URL } from "../../../api/common";
import DoubleClickDiv from "./double-click";
import "./index.css";

const NodeCard = ({ navigate, elem, fetchNodes }) => {
  const [detailPopUp, setDetailPopup] = useState(false);

  const imageCheck = (logo) => {
    return logo && logo.length < 30;
  };

  const openPopUp = (e) => {
    e.stopPropagation();
    setDetailPopup(!detailPopUp);
  };
  const onClick = () => {
    navigate(`${elem.id}`);
  };

  const onDelete = () => {
    deleteNode(elem.id)
      .then((res) => {
        toast.success(`${elem.name} successfully deleted`);
        fetchNodes();
      })
      .catch((_err) => {
        toast.error(`unable to delete node`);
      });
  };

  return (
    <DoubleClickDiv className="col-xl-3 col-lg-4 col-md-6 nb_card_wrapper" onClick={onClick}>
      <div className="node-builder-card">
        {/* card heading */}
        <div className="content-card">
          <div className="node-builder-card-header">
            <div className="card_header">
              {/* <div className="node-builder-card-header-img-box"> */}
              <img
                className="node-builder-card-default-icon"
                src={
                  imageCheck(elem.logo)
                    ? IMAGE_URL + elem.logo
                    : elem.logo
                    ? `data:image/png;base64,${elem.logo}`
                    : defaultIcon
                }
                alt={"img"}
              />
              <div className="text-ellipsis-container">
                <h4 className="text-ellipsis">{elem.name}</h4>
              </div>
            </div>
            {/* </div> */}
            <div className="node-deatil-box " onClick={openPopUp}>
              <MenuIcon />
            </div>

            {detailPopUp ? (
              <div className="node-builder-detail-popup">
                <div
                  className="node-builder-detail-popup-btns"
                  onClick={() => navigate(`/home/nodes/edit/${elem.id}`)}
                >
                  <div className="node-builder-content-edit">
                    <EditIcon /> Edit
                  </div>
                </div>
                <div
                  className="node-builder-detail-popup-btns"
                  onClick={onDelete}
                >
                  <div className="node-builder-content">
                    <DeleteIcon />
                    Delete
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* card category, subCategory, and auth */}
          <div className="node-builder-card-body">
            <div className="parameters">
              <div className="parameters-div1">
                <p className="node-builder-card-body-des">Category</p>
                <p className="node-builder-card-body-des">Sub category</p>
                <p className="node-builder-card-body-des">Auth Type</p>
              </div>

              <div className="parameters-div2">
                <p className="card-body-category">{elem.category}</p>
                <p className="card-body-category">{elem.sub_category}</p>
                <p className="card-body-category">
                  {elem.auth?.type ? elem.auth.type + " Auth" : ""}
                </p>
              </div>
            </div>
            {/* <div className="card-description">
              <p>{elem.description}</p>
            </div> */}
          </div>
        </div>
        {/* horizontal line
        <hr className="node-card-divider" />

         ending button edit and detail
        <div className="node-card-footer-btn-div">
          <button
            className="footer-btn-edit-div"
            onClick={() => navigate(`edit/${elem.id}`)}
          >
            Edit
          </button>
          <button className="footer-btn-details-div" onClick={onClick}>
            View details
          </button>
        </div>  */}

        <div className="node-builder-card-footer-text-box">
          <p className="node-builder-card-footer-text">{elem.description}</p>
        </div>
      </div>
    </DoubleClickDiv>
  );
};

const Loader = () => (
  <div className="col-xl-3 col-lg-6">
    <div className="node-builder-card-sekelton">
      <div className="align-center">
        <div className="node-skelton-data">
          <Skeleton circle={true} height={80} width={80} />
          <div className="left-skelton-container">
            <Skeleton height={10} width={120} />
          </div>
        </div>
        <div className="skelton-menu-btn">
          <Skeleton height={30} width={25} />
        </div>
      </div>
      <div className="skeleton-after-header-created">
        <Skeleton height={10} width={120} />
      </div>

      <div style={{ height: "300px" }} className="skeleton-card-body">
        <div className="right-skelton-container">
          <Skeleton height={10} width={100} />
          <Skeleton height={10} width={100} />
        </div>
        <div className="right-skelton-container mar-l-5">
          <Skeleton height={10} width={100} />
          <Skeleton height={10} width={100} />
        </div>
        <div className="right-skelton-container mar-l-5">
          <Skeleton height={10} width={100} />
          <Skeleton height={10} width={100} />
        </div>
        <div className="bottom-skelton-line-container">
          <Skeleton height={10} width={200} />
          <Skeleton height={10} width={200} />
        </div>
        {/* <div className="bottom-skelton-container">
          <Skeleton height={50} width={70} style={{ marginRight: "10px" }} />
          <Skeleton height={50} width={90} />
        </div> */}
      </div>
    </div>
  </div>
);

const NodesListing = () => {
  const navigate = useNavigate();
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [isMoreRecords, setIsMoreRecords] = useState(true);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedOption, setSelectedOption] = useState("date_desc");

  useEffect(() => {
    getNodes()
      .then((dt) => {
        if (dt) {
          setNodes(dt);
          setLoading(false);
          setSkip(10);
        }
      })
      .catch((_err) => {
        toast.error("Unable to fetch nodes data");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchNodes();
  }, []);

  const handleScroll = () => {
    if (loading) {
      return;
    }
    if (isMoreRecords === true) {
      setLoading(true);
      getNodes(10, skip, search, sortOrder, sortBy)
        .then((res) => {
          if (res && res.length > 0) {
            setNodes((prevGroups) => prevGroups.concat(res));
            if (res.length < 10) {
              setIsMoreRecords(false);
            }
            setLoading(false);
            setSkip(skip + 10);
          } else {
            setLoading(false);
            setIsMoreRecords(false);
          }
        })
        .catch((_err) => {
          setLoading(false);
        });
    }
  };


  const fetchNodes = (sk = skip) => {
    getNodes(10, sk, "", sortOrder, sortBy)
      .then((res) => {
        if (res && res.length > 0) {
          setNodes(res);
          setLoading(false);
          setSkip(10);
          setIsMoreRecords(true);
        } else {
          setLoading(false);
        }
      })
      .catch((_err) => {
        toast.error("Unable to fetch groups data");
        setLoading(false);
      });
  };

  const handleSearchEnter = (event) => {
    // searchGroups
      if (search.length > 0) {
        setLoading(true);
        getNodes(10, 0, search, sortOrder, sortBy)
          .then((res) => {
            if (res) {
              setNodes(res);
              setLoading(false);
            }
            if (res.length < 1) {
              toast.error("No data Found");
            }
          })
          .catch((_err) => {
            toast.error("Unable to fetch groups data");
            setLoading(false);
          });
      }
  };


  useEffect(() => {
    handleSearchEnter()
  }, [search])


  const handleSort = (event) => {
    const value = event;
    let sortOrder, sortBy;
    setSelectedOption(value);

    switch (value) {
      case "date_desc":
        sortOrder = "desc";
        sortBy = "created_at";
        break;
      case "date_asc":
        sortOrder = "asc";
        sortBy = "created_at";
        break;

      case "name_asc":
        sortOrder = "asc";
        sortBy = "name";
        break;

      case "name_desc":
        sortOrder = "desc";
        sortBy = "name";
        break;

      default:
        sortOrder = "desc";
        sortBy = "created_at";
    }

    setSortOrder(sortOrder);
    setSortBy(sortBy);

    setLoading(true);
    getNodes(10, 0, search, sortOrder, sortBy)
      .then((res) => {
        setNodes(res);
        setLoading(false);
      })
      .catch((_err) => {
        toast.error("Unable to fetch groups data");
        setLoading(false);
      });
  };



  useEffect(() => {
    if (search.length === 0) {
      fetchNodes(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="node-builder-main">
      {/* top main heading */}
      <div className="node-builder-heading">
        <div className="node-builder-main-heading">
          <h1>Node Builder</h1>
          <p>Browse all the existing user nodes here</p>
        </div>
        <button className="node_builder_btn" onClick={() => navigate("create")}>
          <CreateIcon />
          Create Node
        </button>
      </div>

      {/* All input and select field */}
      <SearchSortFilter
        setSearch={setSearch}
        onSearch={handleSearchEnter}
        onSort={handleSort}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />

      <div className="p-node-card" id="scrollableDiv">
        <InfiniteScroll
          dataLength={nodes.length}
          next={handleScroll}
          hasMore={isMoreRecords || loading}
          scrollThreshold={"450px"}
          className="row nb_card_row"
          loader={
            <div className="row">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((e, id) => (
                <Loader key={id} />
              ))}
            </div>
          }
        >
          {nodes.map((elem) => (
            <NodeCard
              navigate={navigate}
              elem={elem}
              key={elem.id}
              fetchNodes={fetchNodes}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default NodesListing;
