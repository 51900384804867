import { useFormik } from "formik";
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { Tabs } from "react-bootstrap-tabs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomSelect from "../../../CustomSelect";

import Trash from "../../../../../src/assets/trash-icon";
import Upload from "../../../../../src/assets/upload-icon";
import {
  createNode,
  getNode,
  imageUpload,
  updateNode,
} from "../../../../api/nodes";
import { generateId, getAllStringValues, getInputs, isValidUrl, removeSpaceFromObj } from '../../../utils';
import TabsComponent from "./tabs";

import { IMAGE_URL } from "../../../../api/common";
import "./index.css";

const excludedValues = ["jobId", "timestamp", "isoTimestamp", "guid"];
const method = ["POST", "GET", "PUT", "DELETE"];


const Keydata = ({ name, description, items, updateItems, className, error, handleBlur, touchedError }) => {

  return (
    <div className={`config_box ${className}`}>
      <HeadDes
        head={name}
        des={description}
      />
      {items.length !== 0 ? items.map((item,index)=>{

        let err = error && error[index]
        return(
          <div className="mr_all" key={index}>
            <div className="adding_parameters">
              <div className="space_btw1 input_row">
                <div className="desp_flex1">
                  <label className="labels">Key</label>
                  <input
                    className="inputs"
                    placeholder="Enter the key"
                    value={item?.name}
                    onBlur={handleBlur}
                    name="name"
                    // onChange={updateItems}
                    onChange={(event) => {
                      updateItems(
                        items.map((i) => {
                          if (i.id === item.id) {
                            i.name = event.target.value;
                          }
                          return i;
                        })
                      );
                    }}
                  />
                {err?.name ? <p className="formik_error">{err?.name}</p> : null}
                </div>
                <div className="desp_flex2">
                  <label className="labels">Value</label>
                  <input
                    className="inputs"
                    placeholder="Enter the value"
                    value={item.value}
                    onBlur={handleBlur}
                    name="value"
                    onChange={(event) => {
                      updateItems(
                        items.map((i) => {
                          if (i.id === item.id) {
                            i.value = event.target.value;
                          }
                          return i;
                        })
                      );
                    }}
                  />
                {err?.value ? <p className="formik_error">{err?.value}</p> : null}
                </div>
                <div
                  className="del_btn"
                  onClick={() => {
                    updateItems(items.filter((i) => i.id !== item.id));
                  }}
                >
                  <Trash />
                </div>
              </div>
            </div>
        </div>
        )
      }) : (
        <div className="mr_all" key="first">
        <div className="adding_parameters">
          <div className="space_btw1 input_row">
            <div className="desp_flex1">
              <label className="labels">Key</label>
              <input
                className="inputs"
                placeholder="Select key"
                onBlur={handleBlur}
                name="name"
                onChange={(event) => {
                  updateItems([...items,{ id: generateId(8), name: event.target.value, value: "" }])
                }}
              />
              {err?.name ? <p className="formik_error">{err?.name}</p> : null}
            </div>
            <div className="desp_flex2">
              <label className="labels">Value</label>
              <input
                className="inputs"
                placeholder="Enter the value"
                onBlur={handleBlur}
                name="value"
                onChange={(event) => {
                  updateItems([...items,{ id: generateId(8), value: event.target.value, name: "" }])

                }}
              />
                {err?.value ? <p className="formik_error">{err?.value}</p> : null}
            </div>
          </div>
        </div>
      </div>
      )}

      <div className="mr_all">
        <div
          id="dropzone1"
          onClick={() => {
            updateItems([...items,{ id: generateId(8), name: "", value: "" }]);
          }}
        >
          <label>
            <p className="caption1">+ Add new {name}</p>
          </label>
        </div>
      </div>
    </div>
  );
};

const ApiMethod = ({
  methodValue,
  urlValue,
  handleChange,
  handleChangeMethod,
  errorMethod,
  errorUrl,
  name,
  description,
  className,
  handleBlur,
  handleTouch
}) => {
  const apiOptions = [
    { value:"POST", label: "POST" },
    { value:"GET", label: "GET" },
    { value:"PUT", label: "PUT" },
    { value:"DELETE", label: "DELETE" },
  ]
  return (
    <div className={`API_method_box config_box ${className}`}>
      <HeadDes
        head={name}
        des={description}
      />
      <div className="space_btw1 input_row">
        <div className="desp_flex1 input_wrappers">
          <CustomSelect
            name="method"
            isSearchable={false}
            label="API Method"
            placeholder="Select API Method"
            options={apiOptions}
            onBlur={handleBlur}
            defaultValue={(apiOptions.find(item => item.label === methodValue))}
            value={(apiOptions.find(item => item.label === methodValue))}
            onChange={(e) => handleChangeMethod(e.value)}          
          />
          {errorMethod ? (
            <p className="formik_error">{errorMethod}</p>
          ) : null}


        </div>
        <div className="desp_flex2 input_wrappers">
          <label className="labels">URL</label>
          <input
            className="inputs"
            placeholder="ex:https://www.example.com"
            name="url"
            value={urlValue}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errorUrl ? <p className="formik_error">{errorUrl}</p> : null}
        </div>
      </div>
    </div>
  );
};

const RequestBodyBox = ({ name, value, onChange, errorBody }) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.jsonEditor.set(value);
  }, [value]);

  return (
    <div className="request_body_box config_box">
      <HeadDes
        head={"Request Body"}
        des={"Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien scelerisque ac."}
      />
      <div className="json-editor">
        <JsonEditor
          ref={ref}
          name={name}
          mode="text"
          value={removeSpaceFromObj(value) || {}}
          onChange={onChange}
        />
      </div>
      {errorBody ? <p className="formik_error">{errorBody}</p> : null}
    </div>
  );
};

const StyledInput = ({ lable, handleChange, value, error, name, touchedError, handleBlur, placeholder }) => (
  <div className="left-side">
    <div className="space_btw">
      <div className="leftside-input">
        <label className="labels"> {lable}</label>
        <input
          type="text"
          className="inputs"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {error ? <p className="formik_error">{error}</p> : null}
      </div>
    </div>
  </div>
);

const HeadDes = ({ head, des }) => (
  <div>
    <div className="header_text">{head} </div>
    <p className="description-auth">{des}</p>
  </div>
);

const ImageUpload = ({ onChange }) => (
  <input
    type="file"
    name="logo"
    placeholder="Enter Node Name"
    onChange={onChange}
    value={undefined}
    hidden
    draggable={true}
    accept="image/*"
    alt="select-file"
      />
);

const Statuscodes = ({ name,data,placeholder, description, className, updateData, errorStatusCode }) => {
    return (
    <div className={`config_box ${className}`}>
      <HeadDes
        head={name}
        des={description}
      />

      {Array.isArray(data) ? data.map((item)=>{
        return(
        <div key={item.id} className="status_box_wrapper">
          <div className="status-trash-div">
            <div className="desp_flex3">
              <label className="labels-status">Status code</label>
              <input
              type="number"
                className="inputs"
                placeholder={placeholder}
               
                name="status"
                max={599}
                value={item.status}
                onChange={(event) => {
                  updateData(
                    data.map((i) => {
                      if (i.id === item.id) {
                        i.status = parseInt(event.target.value);
                      }
                      return i;
                    })
                  );
                }}
              />
            </div>
            <div className="del-btn-main-div">
              <div
                className="del_btn1"
                onClick={() => {
                  updateData(data.filter((i) => i.id !== item.id));
                }}
              >
                <Trash />
              </div>
            </div>
          </div>
          <div className="json-editor">
            <JsonEditor
              mode="text"
              name="variables"
              value={item.variables || {}}
              onChange={(value) => {
                updateData(
                  data.map((i) => {
                    if (i.id === item.id) {
                      i.variables = value;
                    }
                    return i;
                  })
                );
              }}
            />
          </div>
        {errorStatusCode ? <p className="formik_error">{errorStatusCode}</p> : null}
        </div>
        )
      }): (
        <div key="first" className="status_box_wrapper">
          <div className="status-trash-div">
            <div className="desp_flex3">
              <label className="labels-status">Status code</label>
              <input
                className="inputs"
                placeholder="Enter the code"
                name="status"
                max={599}
                type="number"
                value={item.status}
                onChange={(event) => {
                  updateData(
                    data.map((i) => {
                      if (i.id === item.id) {
                        i.status = parseInt(event.target.value);
                      }
                      return i;
                    })
                  );
                }}
              />
            </div>
            <div className="del-btn-main-div">
              <div
                className="del_btn1"
                onClick={() => {
                  updateData(data.filter((i) => i.id !== item.id));
                }}
              >
                <Trash />
              </div>
            </div>
          </div>
          <div className="json-editor">
            <JsonEditor
              mode="text"
              name="variables"
              value={item.variables || {}}
              onChange={(value) => {
                updateData(
                  data.map((i) => {
                    if (i.id === item.id) {
                      i.variables = value;
                    }
                    return i;
                  })
                );
              }}
            />
          </div>
        {errorStatusCode ? <p className="formik_error">{errorStatusCode}</p> : null}
        </div>
      )}
      <div className="mr_all">
        <div
          id="dropzone2"
          onClick={() => {
            updateData([
              ...data,
              { id: generateId(6), status: "", variables: {} },
            ]);
          }}
        >
          <label>
            <p className="caption1">+ Add new Status Code</p>
          </label>
        </div>
      </div>
    </div>
  );
};


const validationSchema = Yup.object({
  logo: Yup.string().required(),
  name: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required("Node name required"),
  category: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required("Category required"),
  sub_category: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required(" SubCategory required"),
  // url: Yup.string().required("url is required"),
  url: Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required("Url is required"),
  method: Yup.string().required("method is required"),

  body: Yup.object().required(),
  status_codes: Yup.array().of(Yup.object()).required("Status code is required"),
  params: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      value: Yup.string().required("Value is required")
    })
  ),


  auth: Yup.object().shape({
    type: Yup.string().required('Select an option'),
    username: Yup.string().when(['type', 'auth.username'], {
      is: (type) => type == "Basic",
      then: () => Yup.string().required("Username is required"),
      otherwise: () => Yup.string().notRequired() 
    }),
    password: Yup.string().when(['type', 'auth.password'], {
      is: (type) => type == "Basic",
      then: () => Yup.string().required("Password is required"),
      otherwise: () => Yup.string().notRequired() 
    }),
    url: Yup.string().when(['type', 'auth.url'], {
      is: (type, url) => type == "Session",
      then: () => Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required("Url is required"),
      otherwise: () => Yup.string(),
    }),
    method: Yup.string().when(['type', 'url'], {
      is: (type, method) => type === 'Session',
      then: () => Yup.string().required('Method is required'),
      otherwise: () => Yup.string(),
    }),
    body: Yup.string().when(['type', 'auth.body'], {
      is: (type, body) => type === 'Session',
      then: () => Yup.object().shape({}).required('Body is required'),
      otherwise: () => Yup.object().shape({}),
    }),
    status_codes: Yup.array().when('type', {
      is: (type) => type == 'Session',
      then: () => Yup.array()
        .of(Yup.object().shape({})) // Define your validation for status_codes here if needed
        .required('Status code is required'),
      otherwise: () => Yup.array().of(Yup.object().shape({})).notRequired(),
    }),
  }),

  
  
});



const Details = ({ nodeId, isEdit }) => {
  const [saving, setSaving] = useState(false);
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();


  const formik = useFormik({

    // initialValues: isEdit ? nodesConstantsEdit : nodesConstants,
    enableReinitialize:true,
    initialValues:{},
    validationSchema: validationSchema,    

    onSubmit:async (values) => {
      setDisable(true)

      try {
        values.inputs = inputs;
        values.outputs = outputs;
        values.logo = logoBase64;

        setSaving(true);
        if (isEdit && isEdit === true) {
          updateNode(values)
            .then((res) => {
              if (res.id) {
                formik.resetForm();
                toast.success("Successfully Updated " + values.name);
                setSaving(false);
                setDisable(false)
                setTimeout(() => {
                  navigate(-1);
                }, 0);
              }
              setSaving(false);
            })
            .catch((err) => {
              toast.error("unable to update node with ", err.message);
              setSaving(false);
              setDisable(false);
            });
          return;
        }
        createNode(values)
          .then((res) => {
            if (res.name) {
              toast.success("Successfully Created " + values.name);
              formik.resetForm();
              setSaving(false);
              setDisable(false)
              setTimeout(() => {
                navigate(-1);
              }, 0);
            }
          })
          .catch((err) => {
            toast.error("Unable to create node with ", err.message);
            setSaving(false);
            setDisable(false)
          });
        }
       catch (err) {
        // toast.error("error in node ", err.message);
        setDisable(false)
      }
    },
  });
  const [logoBase64, setLogoBase64] = useState("");

  const flattenObj = (ob) => {

    // The object which contains the
    // final result
    let result = {};

    // loop through the object "ob"
    for (const i in ob) {

        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {

                // Store temp in result
                result[i + '.' + j] = temp[j];
            }
        }

        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }

    }
    return result;
};


  // let inputs = useMemo(() => {
  //   let values = JSON.parse(JSON.stringify(formik.values));
  //   values.auth.status_codes = [];
  //   values.status_codes = [];
  //   let res = getInputs(flattenObj(values))
  //   return res;
  // }, [formik.values]);
  let inputs = useMemo(() => {
    return getInputs(formik.values);
  }, [formik.values]);

  const [outputs, internal] = useMemo(() => {
    const newValue = JSON.parse(JSON.stringify(formik.values));

    let internalValues = {};

    if (formik.values.auth?.status_codes) {
      internalValues = (newValue.auth?.status_codes || []).map(
        (item) => item.variables
      );
      newValue.auth.status_codes = undefined;
    }

    return [
      (formik.values.status_codes || []).map((status) => {
        const opts = getAllStringValues(status.variables);
        return { id: status.id, status: status.status, outputs: opts };
      }),
      getAllStringValues(internalValues),
    ];
  }, [formik.values]);

  inputs = inputs.filter((input) => !internal.find((i) => i === input));
  inputs = inputs.filter((input) => !excludedValues.find((ex) => ex === input));



  const updateAuth = (field, obj) =>{
      removeSpaceFromObj(obj);
    formik.setFieldValue("auth", {
      ...formik.values.auth,
      [field]: obj,
    });

  }

  const onChangeImage = (event) => {
    event.preventDefault(); 
    event.stopPropagation(); 

    let file;
    if (event.target?.files?.length > 0) {
      file = event.target?.files[0]
    } else if (event.dataTransfer?.files?.length > 0) {
      file = event.dataTransfer.files[0]
    }


    //let's grab the image file
    imageUpload(file)
      .then((res) => {
        formik.setFieldValue('logo', res.id)
        setLogoBase64(res.id);
      })
      .catch((err) => {
        toast.error("unable to fetch id", err);
      });

    // const reader = new FileReader();

    // reader.onloadend = (e) => {
    //   setLogoBase64(reader.result);
    // };

    // if (file) {
    //   reader.readAsDataURL(file);
    // }
  };

  const onChangeAuth = (e) => {
      const { value, name } = e.target;
      updateAuth(name, value);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    imageUpload(file)
      .then((res) => {
        setLogoBase64(res.id);
      })
      .catch((err) => {
        toast.error("unable to fetch id", err);
      });

    // if (file && file.type.match("image.*")) {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     setLogoBase64(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
    }

  const imageCheck = (logo) => {
    return logo?.length < 30;
  };

  useEffect(() => {
    if (isEdit === true && nodeId) {
      getNode(nodeId)
        .then((detail) => {
          formik.setValues(detail);
          setLogoBase64(detail.logo);
        })
        .catch((err) => {
          toast.error("unable to get node with ", nodeId);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, nodeId]);

  const authOptions = [
    { value:"Basic", label: "Basic Auth" },
    { value:"Session", label: "Session Auth" }
  ]

  return (
    <>
      <div className="pos_fixed breadcrumb_sec">
        <div className="left_sec">
          <Breadcrumb>
            <Breadcrumb.Item href="#" className="breadCrumb-group">
              <NavLink to="/home/nodes">Nodes</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active href="#">
              {isEdit && isEdit === true ? "Edit Node" : "Create Node"}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="node-builder-heading">
            <div className="node-builder-main-heading">
              <h1>{isEdit && isEdit === true ? "Edit Node" : "Create node"}</h1>
              <p className="sub_text"> {isEdit && isEdit === true ? "View the details of the  node." : "Create your own node"}</p>
            </div>
          </div>
        </div>
        <div className="right_sec">
          <button
            className="node_builder_btn"
            type="submit"
            onClick={() => {
              formik.handleSubmit()
              Object.keys(formik.errors).length > 0 && toast.error("Please check all Fields")
            }}
            // disabled={!formik.isValid || saving}
          >
            {saving === true ? (
              "Saving ..."
            ) : (
              <>
                {isEdit && isEdit === true ? "Update Changes" : "Save Changes"}
              </>
            )}
          </button>
        </div>
      </div>

      {/* body of create node  */}
      <div className="wid-100">
        <div className="nodeDetail_main">
          <div className="left-side-component">
            <div className="header_text1">Node details </div>
            <div className="section_wrapper">
              <div className="first_box">
                <div className="row">
                  <div className="input_wrapper col-lg-6">
                    <StyledInput
                      name={"name"}
                      lable={"Node Name"}
                      value={formik.values.name}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={formik.errors.name}
                      touchedError={formik.touched.name}
                      placeholder={"Enter node name"}
                    />
                    <StyledInput
                      name={"category"}
                      lable={"Category"}
                      value={formik.values.category}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={formik.errors.category}
                      touchedError={formik.touched.category}
                      placeholder={"Enter node category"}
                    />
                    <StyledInput
                      name={"sub_category"}
                      lable={"Sub Category"}
                      value={formik.values.sub_category}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={formik.errors.sub_category}
                      touchedError={formik.touched.sub_category}
                      placeholder={"Enter node sub category"}

                    />
                  </div>
                  <div className="upload_wrapper col-lg-6">
                    <div className="column">
                      <label className="labels">Icon</label>
                    </div>
                    <div
                      className="mr_all"
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={handleDrop}
                    >
                      <div id={logoBase64 === "" ? "dropzone" : "dropzone_pd"}>
                        <label className="label-dropzone">
                          {logoBase64 !== "" && (
                            <img
                              src={
                                imageCheck(logoBase64)
                                  ? IMAGE_URL + logoBase64
                                  : `data:image/png;base64 ,${logoBase64}`
                              }
                              style={{ border: "none" }}
                              width="120"
                              height="120"
                              className="logo-img"
                              alt="logo"
                                                          />
                          )}
                          <ImageUpload onChange={onChangeImage} />
                          {logoBase64 === "" ? (
                            <div className="btn-dropzone"
                            onDrop={onChangeImage}
                            >
                              <div>
                                <p className="caption">
                                  Upload or drag and drop you logo
                                </p>
                                <p className="caption"> here to upload</p>
                              </div>
                              <div className="">
                                <label className="upload_btn">
                                  <ImageUpload onChange={onChangeImage} />
                                  <Upload />
                                  Upload logo
                                </label>
                              </div>
                            {formik.errors.logo ? <p className="formik_error">{formik.errors.logo}</p> : null}
                            </div>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text_input">
                    <label className="labels">Description</label>
                    <textarea
                      className="description w-100"
                      placeholder="Enter description"
                      value={formik.values.description}
                      name="description"
                      onChange={formik.handleChange}
                    />
                </div>
              </div>
            </div>
            <div className="section_wrapper">
              <div className="second_box">
                <HeadDes
                  head={"Configure Authentication"}
                  des={
                    "Build a form with fields for each item your API requires for authentication, such as username, password, subdomain, and more."
                  }
                />
                <div className="col-lg-6 input_wrappers">
                  <CustomSelect
                    name="method"
                    isSearchable={false}
                    label="Authentication Type"
                    defaultValue={(authOptions.find(item => item.value === formik.values.auth?.type))}
                    value={(authOptions.find(item => item.value === formik.values.auth?.type))}
                    placeholder="Select Authentication Type"
                    options={authOptions}
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue("auth", {
                        ...formik.values.auth,
                        type: e.value,
                      })
                    }
                  />
                  { formik.errors.auth?.type ? (<p className="formik_error">{formik.errors.auth?.type}</p>) : null}
                </div>
                {formik.values.auth?.type === "Basic" ?
                (
                  <div className="space_btw1 input_row user_inputs">
                    <div className="desp_flex1 input_wrappers">
                      <label className="labels">Username</label>
                      <input
                        className="inputs"
                        placeholder="Enter Username"
                        name="username"
                        value={formik.values.auth?.username}
                        onChange={(e) => updateAuth("username", e.target.value)}
                      />
                      {formik.errors?.auth?.username ? <p className="formik_error">{formik.errors?.auth?.username}</p> : null}
                    </div>
                    <div className="desp_flex2 input_wrappers">
                      <label className="labels" id="authentication">
                        Password
                      </label>
                      <input
                        className="inputs"
                        placeholder="Enter Password"
                        type="password"
                        name="password"
                        value={formik.values.auth?.password}
                        onChange={(e) => updateAuth("password", e.target.value)}
                      />
                      {formik.errors?.auth?.password ? <p className="formik_error">{formik.errors?.auth?.password}</p> : null}
                    </div>
                  </div>
                ) : "" }
                {formik.values.auth?.type === "Session" ? (
                <div className="auth_session_wrapper">
                  <ApiMethod
                    name={"Session Auth"}
                    description={"Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum"}
                    methodValue={formik.values.auth?.method}
                    urlValue={formik.values.auth?.url}
                    errorMethod={formik.errors.auth?.method}
                    errorUrl={formik.errors.auth?.url}  
                    handleBlur={formik.handleBlur}
                    handleChange={onChangeAuth}
                    handleTouch={formik.touched.auth?.url}
                    handleChangeMethod={(value) => {
                      formik.setFieldValue("auth", {
                        ...formik.values.auth,
                        method: value,
                      })
                    }}
                  />


                  <Keydata
                    className="Parameter_box"
                    description={`Use the session authentication type if
                    you need to collect some information
                    from your users, for example a user name
                    and password, and then make a request to
                    your API to exchange that information
                    for a token or session key, which you
                    will use to authorize subsequent API
                    requests`}
                    name="Parameter"
                    value={formik.values.auth?.params}
                    items={formik.values.auth?.params || [{ id: generateId(8), name: "", value: "" }]}
                    updateItems={(items) => updateAuth("params", items)}
                    handleBlur={formik.handleBlur}
                    touchedError={formik.touched?.auth?.params}
                    error={formik.errors?.auth?.params}

                  />
                  <Keydata
                    className="headers_box"
                    description={`Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam
                  sapien scelerisque ac.`}
                    name="Header"
                    value={formik.values.auth?.headers}
                    items={formik.values.auth?.headers || [{ id: generateId(8), name: "", value: "" }]}
                    updateItems={(items) => updateAuth("headers", items)}
                    handleBlur={formik.handleBlur}
                    error={formik.errors?.auth?.headers}
                    touchedError={formik.touched?.auth?.headers}

                  />
                  <RequestBodyBox
                    name="body"
                      value={removeSpaceFromObj(formik.values.auth?.body)}
                      onChange={(value) => updateAuth("body", value)}
                      errorBody={formik.errors.auth?.body}
                    />

                <Statuscodes
                  className="status_box"
                  description={`Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum.`}
                  name={"Status code"}
                  placeholder={"Enter the status code"}
                  data={formik.values.auth?.status_codes || [
                    {
                      id: generateId(6),
                      status: 0,
                      variables: {},
                    },
                  ]}
                  updateData={(data) =>
                    formik.setFieldValue("auth.status_codes", data)
                  }
                  errorStatusCode={formik.errors.auth?.status_codes}
                  />

                </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="section_wrapper">
              <div className="third_box api_config_wrapper">
                <ApiMethod
                  methodValue={formik.values.method}
                  urlValue={formik.values.url}
                  handleChange={formik.handleChange}
                  handleChangeMethod={(value) => {
                    formik.setFieldValue("method",  value)
                  }}
                  errorMethod={formik.errors.method}
                  errorUrl={formik.errors.url}
                  name={"Configure your API"}
                  description={"Build a form with fields for each item your API requires for authentication, such as username, password, subdomain, and more."}
                />

                <Keydata
                  handleBlur={formik.handleBlur}
                  touchedError={formik.touched?.params}
                  error={formik.errors.params}
                  className="Parameter_box"
                  name="Parameter"
                  description={`Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum`}
                  value={formik.values.params || []}
                  items={formik.values.params || [{ id: generateId(8), name: "", value: "" }]}
                  updateItems={(items) => formik.setFieldValue("params", items)}

                />

                <Keydata
                  handleBlur={formik.handleBlur}
                  touchedError={formik.touched?.headers}
                  error={formik.errors.headers}
                  className="headers_box"
                  description={`Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum`}
                  name={"Header"}
                  value={formik.values.headers || []}
                  items={formik.values.headers || [{ id: generateId(8), name: "", value: "" }]}
                  updateItems={(items) => formik.setFieldValue("headers", items)}
                  // updateItems={(items) => console.log(items, '--sad')}
                />

                <RequestBodyBox
                  value={formik.values?.body}
                  name={"body"}
                  onChange={(value) => {
                    removeSpaceFromObj(value);
                    formik.setFieldValue("body", value)
                  }}
                  errorBody={formik.errors.body}
                />

            <Statuscodes
              className="status_box"
              description={`Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien scelerisque ac.`}
              name={"Status Code"}
              placeholder={"Enter the status code"}
              data={formik.values.status_codes || [
                {
                  id: generateId(6),
                  status: 0,
                  variables: {},
                }
              ]}
              updateData={(data) =>
                formik.setFieldValue("status_codes", data)
              }
              errorStatusCode={formik.errors?.status_codes}
            />
              </div>
            </div>
          </div>
        </div>
        <div className="tab-componet">
          <div className="main-tab-div">
            <div className="inner-tab-div">
              <div className="top-content">Your Responses</div>
              <Tabs>
                <TabsComponent label={"Inputs"} array={inputs} />
                <TabsComponent label={"Outputs"} array={outputs} />
                <TabsComponent label={"Internals"} array={internal} />
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;