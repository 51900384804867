import { Tab } from "react-bootstrap-tabs";
import ArrowIcon from "../../../../assets/arrowIcon";

const Component = ({ type, status, data }) => {
  return (
    <div className="tab-boxes">
      {type === "Outputs" ? <h6>{status}</h6> : <></>}
      {type === "Outputs" ? <ArrowIcon /> : <></>}
      {type === "Outputs" ? (
        <div className="d-flex flex-column">
          {data?.data.map((item, i) => (
            <div key={i}>{item?.replace(/ /g, "")}</div>
          ))}
        </div>
      ) : (
        <div>{data}</div>
      )}
    </div>
  );
};

const TabsComponent = ({ array, label }) => {
  return (
    <Tab label={label}>
      <div className="main-in-tab" style={{ paddingBottom: "99px"}}>
        {array.map((val, index) => (
          <Component type={label} data={val} status={val?.status} key={index} />
        ))}
      </div>
    </Tab>
  );
};
export default TabsComponent;
