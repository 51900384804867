import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ViewNode from "./Components/NodesBuilder/view";
import CreateNode from "./Components/NodesBuilder/CreateNodes";
import NodesListing from "./Components/NodesBuilder/listing";
import { ErrorPage } from "@idflow/styles";


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";

import * as Sentry from "@sentry/react";
import { useEffect } from "react";

const DSN =
  "https://48ed957d34d342cbbbfe55ed9c4da998@o4505214857838592.ingest.sentry.io/4505584043884544";

Sentry.init({
  dsn: DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const path = (p) => "/home/nodes" + p;
const router = createBrowserRouter([
  {
    path: path("/:nodeId"),
    element: <ViewNode />,
  },
  {
    path: path("/create"),
    element: <CreateNode />,
  },
  {
    path: path("/edit/:nodeId"),
    element: <CreateNode />,
  },
  {
    path: path(""),
    element: <NodesListing />,
  },
  {
    path: "*",
    element: <ErrorPage />
  }
]);

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
};

export default App;
