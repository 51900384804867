import React from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";

import "./index.css";
import Details from "./details";

const CreateNode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const routeName = location.pathname;
  const isEdit = routeName.includes("edit");
  const nodeId = routeName.split("/").pop();

  if (!nodeId && isEdit === true) {
    navigate("/nodes");
  }

  return (
    <div className="main_div">
      <div className=" body_component">
        <Details isEdit={isEdit} nodeId={nodeId} />
      </div>
    </div>
  );
};

export default CreateNode;
